import { FaArrowCircleUp } from 'react-icons/fa'; 

import './Scroll-Button.scss';
  
export const ScrollButtonComponent = () =>{ 
  
  const scrollToTop = () =>{ 
    window.scrollTo({ 
      top: 0,  
      behavior: 'smooth'
    }); 
  }; 
  
  return ( 
     <FaArrowCircleUp id ="back-to-top-button" onClick={scrollToTop}  /> 
  ); 
} 
