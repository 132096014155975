import { Container, Row, Col, Image } from 'react-bootstrap';
import ProfilePicture from '../../assets/pictures/Pic.jpg'

import './Intro-View.scss';

export const IntroView = () => {
    return (
        <Container>
            <Row>
                <Col md={7} sm={6}>
                    <Row>
                        <h1 className="section-title">I'm Jeriko Carrera,</h1>
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                <p>...a passionate web developer with a keen eye for design, aesthetics, and functionality.</p>
                            </Row>
                            <Row>
                                <p>Embracing the challenge of complex problems, I thrive on continuous learning and relish the opportunity to craft solutions from scratch. </p>
                            </Row>
                            <Row>
                                <p>Whether it's unraveling bugs or sculpting captivating websites, I pour my heart into every project. </p>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <h4 className="section-focus">But it's not just about the code.</h4>
                            </Row>
                            <Row>
                                <p> I cherish the conversations surrounding my work, leveraging my strong communication skills to collaborate effectively within teams.  </p>
                            </Row>
                            <Row>
                                <p>Join me on this journey as we transform ideas into stunning digital experiences, one line of code at a time. </p>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col md={5} sm={6}>
                    <Row>
                        <Container className="image-wrapper">
                            <Image id="intro-image" src={ProfilePicture} alt="Profile"></Image>
                        </Container>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
};