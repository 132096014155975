/* eslint-disable no-unused-vars */
import React, { useRef, useEffect, useState } from 'react';
import { FaArrowCircleDown } from 'react-icons/fa';
import { Container, Row } from 'react-bootstrap';
import { IntroView } from '../Intro-View/Intro-View';
import { AboutView } from '../About-View/About-View';
import { ContactView } from '../Contact-View/Contact-View';
import { ProjectView } from '../Projects-View/Projects-View';
import { FooterView } from '../Footer-View/Footer-View';

import '../../App.scss';
import './Main-View.scss';

export const MainView = () => {
    const introView = useRef(null);
    const projectsView = useRef(null);
    const aboutView = useRef(null);
    const contactView = useRef(null);
    const footerView = useRef(null);

    const [introVisible, setIntroVisible] = useState(false);
    const [projectsVisible, setProjectsVisible] = useState(false);
    const [aboutVisible, setAboutVisible] = useState(false);
    const [contactVisible, setContactVisible] = useState(false);
    const [footerVisible, setFooterVisible] = useState(false);

    const [viewedSections, setViewedSections] = useState({
        intro: false,
        projects: false,
        about: false,
        contact: false,
        footer: false,
    });

    const scrollTo = (ref) => {
        ref.current.scrollIntoView();
    };

    useEffect(() => {
        const observeIntersection = (ref, setVisibleState, componentName) => {
            const observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setVisibleState(true);
                        setViewedSections(prevState => ({
                            ...prevState,
                            [componentName]: true,
                        }));
                    }
                });
            }, { threshold: 0.5 });
            observer.observe(ref.current);
            return () => observer.unobserve(ref.current);
        };

        observeIntersection(introView, setIntroVisible, 'intro');
        observeIntersection(projectsView, setProjectsVisible, 'projects');
        observeIntersection(aboutView, setAboutVisible, 'about');
        observeIntersection(contactView, setContactVisible, 'contact');
        observeIntersection(footerView, setFooterVisible, 'footer');

    }, []);

    return (
        <Container id="main-container">
            <Row className={`fade-in-section section-block intro ${viewedSections.intro ? 'is-visible' : ''}`} id="intro-view" ref={introView}>
                <IntroView />
                <FaArrowCircleDown id="bottom-button" onClick={() => scrollTo(projectsView)} />
            </Row>
            <Row className={`fade-in-section section-block projects ${viewedSections.projects ? 'is-visible' : ''}`} id="projects-view" ref={projectsView}>
                <ProjectView />
                <FaArrowCircleDown id="bottom-button" onClick={() => scrollTo(aboutView)} />
            </Row>
            <Row className={`fade-in-section section-block about ${viewedSections.about ? 'is-visible' : ''}`} id="about-view" ref={aboutView}>
                <AboutView />
                <FaArrowCircleDown id="bottom-button" onClick={() => scrollTo(contactView)} />
            </Row>
            <Row className={`fade-in-section section-block contact ${viewedSections.contact ? 'is-visible' : ''}`} id="contact-view" ref={contactView}>
                <ContactView />
                <FaArrowCircleDown id="bottom-button" onClick={() => scrollTo(footerView)} />
            </Row>
            <Row className={`fade-in-section section-block footer ${viewedSections.footer ? 'is-visible' : ''}`} id="footer-view" ref={footerView}>
                <FooterView />
            </Row>
        </Container>
    );
};
