import { Container, Image, Button, Offcanvas, Col, Row } from 'react-bootstrap';
import { useState, useEffect, useMemo } from 'react';
import Project1 from '../../assets/screenshots/PokedexScreenshot.png';
import Project2 from '../../assets/screenshots/JeriflixReactScreenshot.png';
import Project3 from '../../assets/screenshots/JeriflixAngularScreenshot.png';
import Project4 from '../../assets/screenshots/MeetAppScreenshot.png';
import Project5 from '../../assets/screenshots/ChatAppScreenshot.png';
import Project6 from '../../assets/screenshots/OnlineAuthoScreenshot.png';
import Project7 from '../../assets/screenshots/PortfolioScreenshot.png';
import Project8 from '../../assets/screenshots/PythonRecipeScreenshot.png';
import Project9 from '../../assets/screenshots/MovieAPIScreenshot.png';
import Project10 from '../../assets/screenshots/RecipeAppScreenshot.png';
import Project11 from '../../assets/screenshots/AIChatBotScreenshot.png';
import Project12 from '../../assets/screenshots/SimpleMusicAppScreenshot.png';
import Gif1 from '../../assets/gifs/GIF1.gif'
import Gif2 from '../../assets/gifs/GIF2.gif'
import Gif3 from '../../assets/gifs/GIF3.gif'
import Gif4 from '../../assets/gifs/GIF4.gif'
// import Gif5 from '../../assets/gifs/GIF5.gif'
// import Gif6 from '../../assets/gifs/GIF6.gif'
import Gif7 from '../../assets/gifs/GIF7.gif'
import Gif10 from '../../assets/gifs/GIF10.gif'
import Gif11 from '../../assets/gifs/GIF11.gif'
import Gif12 from '../../assets/gifs/GIF12.gif'
import CaseStudy from '../../assets/files/JeriflixCaseStudy.pdf';

import './Projects-View.scss';

export const ProjectView = () => {
    const projects = useMemo(() => [
        {
            name: 'Jeriflix (React)',
            subtitle: 'A movie application',
            image: Project2,
            gif: Gif2,
            description: 'Jeriflix is a movie information Web App that connects to its own database to allow its users to create accounts and learn about movies!',
            username: 'KingdomCome',
            password: 'KingdomCome',
            repoLink: 'https://github.com/lazyjinn1/Jeriflix-Client',
            liveLink: 'https://jeriflix.netlify.app',
            technologies: ['React', 'CSS/SCSS', 'JavaScript', 'Front-end', 'Git', 'MongoDB', 'HTML', 'Node', 'React-Bootstrap'],
        },
        {
            name: 'Jeriflix (Angular)',
            subtitle: 'A movie application',
            image: Project3,
            gif: Gif3,
            description: 'This is an updated version of the Jeriflix movie application that uses the same backend but utilizes Angular as its framework.',
            username: 'KingdomCome',
            password: 'KingdomCome',
            repoLink: 'https://github.com/lazyjinn1/Jeriflix-Angular-client',
            liveLink: 'https://lazyjinn1.github.io/Jeriflix-Angular-client/welcome',
            caseStudy: CaseStudy,
            technologies: ['Angular', 'CSS/SCSS', 'HTML', 'Front-end', 'MongoDB', 'Git', 'Express', 'TypeScript', 'Node', 'Angular-Material']
        },
        {
            name: 'Chat',
            subtitle: 'A chat application',
            image: Project5,
            gif: Project5,
            description: 'Chat utilizes Google\'s OAuth and Firebase in conjunction with React-Native to deliver an anonymous chat-room app with different chat rooms!',
            repoLink: 'https://github.com/lazyjinn1/ChatApp',
            technologies: ['React-Native', 'CSS/SCSS', 'JavaScript', 'Front-end', 'HTML', 'Git', 'Firestore/Firebase', 'Mobile Development']
        },
        {
            name: 'Meet',
            subtitle: 'A meeting application',
            image: Project4,
            gif: Gif4,
            description: 'Meet brings forth a variety of different tools to create an events application that connects to Google\'s Calendar API.',
            repoLink: 'https://github.com/lazyjinn1/MeetApp',
            liveLink: 'https://lazyjinn1.github.io/MeetApp/',
            technologies: ['React', 'CSS/SCSS', 'JavaScript', 'Front-end', 'Git', 'AWS', 'HTML', 'Node']
        },
        {
            name: 'Pokedex',
            subtitle: 'A Pokedex application',
            image: Project1,
            gif: Gif1,
            description: 'Pokedex utilizes raw JavaScript in combination with HTML, CSS and a connection to the PokeAPI to help you learn about your favorite Pokemon.',
            repoLink: "https://github.com/lazyjinn1/Pokedex-App",
            liveLink: 'https://lazyjinn1.github.io/Pokedex-App/',
            technologies: ['JavaScript', 'CSS/SCSS', 'HTML', 'Git', 'Front-end', 'JQuery']
        },
        {
            name: 'Online Autho',
            subtitle: 'An authorization form application',
            image: Project6,
            gif: Project6,
            description: 'Online Autho utilizes Firebase together with React-Native to create an authorization form for your organization!',
            technologies: ['React-Native', 'CSS/SCSS', 'JavaScript', 'HTML', 'Git', 'Front-end', 'Firestore/Firebase', 'Mobile Development']
        },
        {
            name: 'Portfolio',
            subtitle: 'My portfolio application',
            image: Project7,
            gif: Gif7,
            description: 'This is the current site that we are on. It utilizes React and React-Bootstrap to display my work and progress.',
            liveLink: 'https://lazyjinn1.github.io/web-dev-portfolio/',
            technologies: ['React', 'CSS/SCSS', 'JavaScript', 'HTML', 'Git', 'Front-end', 'React-Bootstrap']
        },
        {
            name: 'CLI Recipe',
            subtitle: 'A Recipe application',
            image: Project8,
            gif: Project8,
            description: 'This is a CLI application written entirely in Python and utilizes MySQL databases and an ORM for conversions.',
            repoLink: 'https://github.com/lazyjinn1/PythonRepo/',
            technologies: ['Python', 'SQL', 'MySQL', 'Back-end', 'Git']
        },
        {
            name: 'Movie API',
            subtitle: 'A Custom Movie API',
            image: Project9,
            gif: Project9,
            description: 'This is the custom Movie API that I constructed for the Jeriflix websites. Utilizing Node and Express for ease of production.',
            repoLink: 'https://github.com/lazyjinn1/Movie-API',
            technologies: ['Express', 'Node', 'MongoDB', 'Back-end', 'Git']
        },
        {
            name: 'Recipe App',
            subtitle: 'A Recipe App',
            image: Project10,
            gif: Gif10,
            description: 'This is a Recipe application created using the Python framework, Django, to allow users to log in and learn recipes or add their own.',
            username: 'ment0rCF',
            password: 'Ment0r@CareerF0undry',
            repoLink: 'https://github.com/lazyjinn1/Recipe-App',
            liveLink: 'https://recipe-app-aexz.onrender.com/',
            technologies: ['Python', 'SQL', 'Back-end', 'Front-end', 'Django', 'Git']
        },
        {
            name: 'AI Hype Bot',
            subtitle: 'An AI Chat Bot',
            image: Project11,
            gif: Gif11,
            description: 'This is a fun AI chat application made together with another developer. It uses Python and JavaScript together with the OpenAI API.',
            repoLink: 'https://github.com/lazyjinn1/PortfolioChatBot',
            technologies: ['Python', 'OpenAI', 'JavaScript', 'Back-end', 'Front-end', 'Git', 'AWS']
        },
        {
            name: 'Simple Music App',
            subtitle: 'A Music Clicker Game',
            image: Project12,
            gif: Gif12,
            description: 'This is a mobile music and clicker game application created in conjunction with musician and rapper, Dragon.',
            shoutout: 'Check out Dragon on Spotify here!',
            repoLink: 'https://github.com/lazyjinn1/Simple-Music-App',
            technologies: ['React-Native', 'JavaScript', 'Front-end', 'Git', 'Mobile Development', 'CSS/SCSS']
        },
    ], []);

    const [isMobile, setIsMobile] = useState(false);

    const checkIfMobile = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth <= 720) {
            setIsMobile(true);
            setItemsPerPage(4);
        } else {
            setItemsPerPage(6);
            setIsMobile(false);
        }
    }

    useEffect(() => {
        checkIfMobile();
        window.addEventListener('resize', checkIfMobile());
        return () => window.removeEventListener('resize', checkIfMobile());
    }, []);


    const [chosenApp, setChosenApp] = useState(null);
    const [show, setShow] = useState(false);
    const [filteredProjects, setFilteredProjects] = useState(projects);
    const [allTechnologies, setAllTechnologies] = useState([]);
    const [currentFilter, setCurrentFilter] = useState([]);
    const [clickedButtons, setClickedButtons] = useState(Array(allTechnologies.length).fill(true));
    const [hoveredProjects, setHoveredProjects] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState();


    const [currentIndex, setCurrentIndex] = useState(0);
    const totalPages = Math.ceil(projects.length / itemsPerPage);

    const handleNext = () => {
        setCurrentIndex(currentIndex === totalPages - 1 ? currentIndex : currentIndex + 1);
    };

    const handlePrev = () => {
        setCurrentIndex(currentIndex === 0 ? currentIndex : currentIndex - 1);
    };


    const renderFilteredProjects = () => {
        const startIndex = currentIndex * itemsPerPage;
        const endIndex = Math.min(startIndex + itemsPerPage, projects.length);
        return filteredProjects.slice(startIndex, endIndex).map((project, index) => (
            <Col md={5} key={index}>
                <Container key={index} className="projects">
                    <Button
                        className={hoveredProjects.some(proj => proj.name === project.name) ? "m-1 btn-dark highlighted" : "m-1 btn-dark"}
                        onClick={() => handleShow(project)}
                    >
                        <Image
                            className="project-image"
                            src={project.image}
                            alt={`Project ${index + startIndex + 1}`}
                        />
                        <Container className="caption">
                            <Container className="main-technology">{project.technologies[0]}</Container>
                        </Container>
                    </Button>
                </Container>
            </Col>
        ));
    };

    const renderFullProjects = () => {
        const startIndex = currentIndex * itemsPerPage;
        const endIndex = Math.min(startIndex + itemsPerPage, projects.length);
        return projects.slice(startIndex, endIndex).map((project, index) => (
            <Col md={6} key={index}>
                <Container key={index} className="projects">
                    <Button
                        className={hoveredProjects.some(proj => proj.name === project.name) ? "m-1 btn-dark highlighted" : "m-1 btn-dark"}
                        onClick={() => handleShow(project)}
                    >
                        <Image
                            className="project-image"
                            src={project.image}
                            alt={`Project ${index + startIndex + 1}`}
                            rounded
                        />
                        <Container className="caption">
                            <Container className="main-technology">{project.technologies[0]}</Container>
                        </Container>
                    </Button>
                </Container>
            </Col>
        ));
    };

    useEffect(() => {
        let allTechnologiesMerged = [];
        for (let i = 0; i < projects.length; i++) {
            allTechnologiesMerged = allTechnologiesMerged.concat(projects[i].technologies);
        }
        let result = allTechnologiesMerged.filter((technology, index) => allTechnologiesMerged.indexOf(technology) === index);
        let sortedResult = result.sort();
        setAllTechnologies(sortedResult);
        setCurrentFilter(sortedResult)
        setClickedButtons(Array(sortedResult.length).fill(true));
    }, [projects])

    useEffect(() => {
        const filterResult = projects.filter(item => item.technologies.some(technology => currentFilter.includes(technology)));
        setFilteredProjects(filterResult);

    }, [currentFilter, projects]);

    const handleShow = (project) => {
        setChosenApp(project);
        setShow(true);
    };

    const handleClose = () => {
        setChosenApp(null);
        setShow(false);
    };

    const isFiltered = (technology) => {
        if (currentFilter.includes(technology)) {
            handleRemoveFilter(technology);
        } else {
            handleAddFilter(technology);
        }
    };

    const isClicked = (index) => {
        const updatedButtons = [...clickedButtons];
        updatedButtons[index] = !updatedButtons[index];
        setClickedButtons(updatedButtons);
    }

    const handleFilter = (technology, index) => {
        isFiltered(technology);
        isClicked(index);
    }

    const handleRemoveFilter = (technology) => {
        const updatedFilter = currentFilter.filter(tech => tech !== technology);
        const sortedFilter = updatedFilter.sort();
        setCurrentFilter(sortedFilter);
    }

    const handleAddFilter = (technology) => {
        const updatedFilter = [...currentFilter, technology];
        const sortedFilter = updatedFilter.sort();
        setCurrentFilter(sortedFilter);
    }

    const handleClear = () => {
        setCurrentFilter([]);
        setClickedButtons(Array(allTechnologies.length).fill(false));
        setCurrentIndex(0);
    }

    const handleShowAll = () => {
        const updatedFilter = [...allTechnologies];
        const sortedFilter = updatedFilter.sort();
        setCurrentFilter(sortedFilter);
        setClickedButtons(Array(allTechnologies.length).fill(true));
    }

    const handleHoveredProjects = (technology) => {
        const filterResult = projects.filter(item => item.technologies.includes(technology));
        setHoveredProjects(filterResult);
    }

    const handleMouseEnter = (technology) => {
        handleHoveredProjects(technology);
    }

    const handleMouseLeave = () => {
        handleHoveredProjects([]);
    }

    return (
        <Container id="main-projects-container">
            <Row>
                {!isMobile ?
                    <Col md={5}>
                        <h1 className="section-subtitle">Skills:</h1>
                        <Row id="skills-section">
                            <Row className="button-skills-list">
                                {allTechnologies.map((technology, index) => (
                                    <Col key={index} sm={4} md={4} lg={4}>
                                        <Row>
                                            <Button
                                                onMouseEnter={() => handleMouseEnter(technology)}
                                                onMouseLeave={handleMouseLeave}
                                                className={clickedButtons[index] ? "custom-btn m-1 button-clicked" : "custom-btn m-1 btn-light"}
                                                onClick={() => handleFilter(technology, index)}>
                                                {technology}
                                            </Button>
                                        </Row>
                                    </Col>
                                ))}
                            </Row>

                            <Row>
                                <Button
                                    className='custom-btn m-1 p-0 w-100 btn-info'
                                    onClick={() => handleClear()}>
                                    <h4>Clear skills</h4>
                                </Button>
                            </Row>
                        </Row>
                    </Col>
                    : null}

                <Col md={7}>
                    <h1 className="section-subtitle">Projects:</h1>
                    {currentFilter.length === 0 && !isMobile ?
                        <Container className="no-skills-section">
                            <h4 className="no-skills-text">Please select a skill:</h4>
                            <Button className="show-all-button custom-btn m-1" variant="info" onClick={handleShowAll}><h4>Show all</h4></Button>
                        </Container>
                        : <Container id="projects-carousel" className="carousel" data-ride="carousel">
                            {
                                isMobile ?
                                    (projects.length > itemsPerPage && currentIndex !== 0) &&
                                    <Button className="carousel-control-prev bg-transparent" onClick={handlePrev} role="button" data-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="sr-only">Prev</span>
                                    </Button>
                                    :
                                    (filteredProjects.length > itemsPerPage && currentIndex !== 0) &&
                                    <Button className="carousel-control-prev bg-transparent" onClick={handlePrev} role="button" data-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="sr-only">Prev</span>
                                    </Button>
                            }
                            <Container className="carousel-inner">
                                <Row id="projects-section">
                                    {isMobile ? renderFullProjects() : renderFilteredProjects()}
                                </Row>
                            </Container>

                            {
                                isMobile ?
                                    (projects.length > itemsPerPage && currentIndex !== totalPages - 1) &&
                                    <Button className="carousel-control-next bg-transparent" onClick={handleNext} role="button" data-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="sr-only">Next</span>
                                    </Button>
                                    :
                                    (filteredProjects.length > itemsPerPage && currentIndex !== totalPages - 1) &&
                                    <Button className="carousel-control-next bg-transparent" onClick={handleNext} role="button" data-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="sr-only">Next</span>
                                    </Button>
                            }

                        </Container>
                    }
                    {isMobile ? null :
                        <Offcanvas className="image-sidebar w-75 p-0" show={show} onHide={handleClose} placement="start">
                            {chosenApp && (
                                <Container>
                                    <Offcanvas.Body>
                                        <Container className="w-100 pr-5 pb-5">
                                            <Image className="offcanvas-gif" src={chosenApp.gif} alt="project gif" />
                                        </Container>
                                    </Offcanvas.Body>
                                </Container>
                            )}
                        </Offcanvas>
                    }

                    <Offcanvas className="info-sidebar p-0" show={show} onHide={handleClose} placement="end">
                        {chosenApp && (
                            <Container>
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title><h4>{chosenApp.name}</h4></Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <Container>
                                        <Row>
                                            <Col>
                                                <h5>{chosenApp.subtitle}</h5>
                                                <p>{chosenApp.description}</p>
                                            </Col>
                                            <Col>
                                                <h5>Technologies used:</h5>
                                                <p>{chosenApp.technologies.join(', ')}</p>
                                            </Col>
                                            {
                                                (chosenApp.username && chosenApp.password) &&
                                                <Col>
                                                    <h5>Login with:</h5>
                                                    <p>Username: {chosenApp.username}</p>
                                                    <p>Password: {chosenApp.password}</p>
                                                </Col>
                                            }
                                        </Row>
                                        <Row>
                                            {chosenApp.repoLink && <Button className="mb-2" href={chosenApp.repoLink} target="_blank">Repository link</Button>}
                                            {chosenApp.liveLink && <Button className="mb-2" href={chosenApp.liveLink} target="_blank">Live Link</Button>}
                                            {chosenApp.caseStudy && <Button className="mb-2" href={chosenApp.caseStudy} target="_blank">Case Study</Button>}
                                            {chosenApp.shoutout && <Button className="mb-2" href="https://open.spotify.com/artist/22KiwdmkiKl8uk8joTEbSa?si=u2WDOS3XRNy_i7vEZR00Lw" target="_blank">{chosenApp.shoutout}</Button>}
                                        </Row>
                                    </Container>
                                </Offcanvas.Body>
                            </Container>
                        )}
                    </Offcanvas>
                </Col>
            </Row>
        </Container>
    );
};
