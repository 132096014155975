import { Container, Row } from 'react-bootstrap'

import Resume from '../../assets/files/JerikoCarreraResume.pdf';

import './Footer-View.scss';

export const FooterView = () => {
    return (
        <Container className = "footer-section">
            <Row className = "footer-content">
                <blockquote className="blockquote">
                    <p>
                        “I found I could say things with color and shapes that I couldn’t say any other way—things I had no words for.”
                    </p>
                    <footer className="blockquote-footer">
                        <cite title="Source Title">Georgia O’Keeffe</cite>
                    </footer>
                </blockquote>
                <Container>
                    <ul className = "links">
                        <li><a href= {Resume} id="button" download target="_blank" rel="noreferrer"><p>CV</p></a></li>
                        <li><a href = "tel:+1-562-416-1789" target="_blank" rel="noreferrer"><p>Phone</p></a></li>
                        <li><a href = "mailto:carrerajeriko@gmail.com" target="_blank" rel="noreferrer"><p>Email</p></a></li>
                        <li><a href="https://github.com/lazyjinn1" target="_blank" rel="noreferrer"><p>Github</p></a></li>
                        <li><a href="https://linkedin.com/in/CarreraJeriko" target="_blank" rel="noreferrer"><p>LinkedIn</p></a></li>
                    </ul>
                </Container>
            </Row>
        </Container>

    )
}