import './Welcome-Page.scss';
import { Container } from 'react-bootstrap';
import HeroBanner from '../../assets/backgrounds/HeroBanner.png'
import { FaArrowCircleDown } from 'react-icons/fa';

import Resume from '../../assets/files/JerikoCarreraResume.pdf';

export const WelcomePageComponent = ({ scrollTo, nextSection }) => {

    return (
        <Container
            className='p-5 text-center bg-image'
            style={{
                backgroundImage: `url(${HeroBanner})`,
                height: '100vh',
            }}
            fluid
        >
            <Container className='d-flex justify-content-center align-items-center h-100'>
                <Container className='text-white'>
                    <h1 className='mb-3'>Jeriko Carrera</h1>
                    <h4 className='mb-3'>Web Developer</h4>
                    <a href={Resume} className='resume-button' id="button" download target="_blank" rel="noreferrer"><h5>Resume</h5></a>
                    <br/>
                    <br/>
                    <br/>
                    <FaArrowCircleDown id="bottom-button" onClick={() => scrollTo(nextSection)} />

                </Container>
            </Container>
        </Container>
    )
} 
