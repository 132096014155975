import { Container, Row, Col, Image } from 'react-bootstrap';
import AboutPicture from '../../assets/pictures/AboutPicture.png'

import './About-View.scss';

export const AboutView = () => {
    return (
        <Container id="about-container">
            <Row>
                <Col md={5} sm={3}>
                    <Row>
                        <Container className="image-wrapper">
                            <Image id="about-image" src={AboutPicture} alt="Profile"></Image>
                        </Container>
                    </Row>
                </Col>
                <Col md={7}>
                    <Row id="about-section">
                        <Row>
                            <h1 className="section-title">About me</h1>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <p>As the son of an artist, I've always had a deep appreciation for creativity.</p>
                                    <p>While on a personal level, I've loved games and computers since I was a kid.
                                        I was even a Twitch streamer at some point!</p>

                                    <p>Combining my love for computers with my passion for art, I discovered this craft, Web Development—a perfect fusion of my interests.</p>
                                </Row>
                            </Col>

                            <Col>
                                <Row>
                                    <p>Outside of the digital space, I frequently enjoy traveling, trying new foods, and hosting game nights.</p>
                                    <p>I also do volunteer work, participate in an art club and love going out for a night in the town!</p>
                                    <p>Just like coding, these are all just different facets of my life. I wouldn't have it any other way!</p>
                                </Row>
                            </Col>
                        </Row>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
};